import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies'
import VueMoment from 'vue-moment'
import moment from 'moment'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './language/i18n'
import vueAwesomeCountdown from 'vue-awesome-countdown'
require('./api/prototype')

Vue.use(VueMoment, {moment})
Vue.use(VueCookies)
Vue.use(vueAwesomeCountdown)
VueCookies.config('1d')

Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  vuetify,
  VueCookies,
  render : h => h(App)
}).$mount('#app')
