<template>
<footer>
  <nav>
    <!-- PC fNav -->
    <div class="fNav_pc only_pc wrap1160">
      <ul class="fNav-1">
        <li>
          <a @click="goto($define.PAGE_NAMES.TOP_PAGE_NAME)">{{$t("PAGE_TOP")}}</a>
        </li>
        <li><a class="lang" @click="$i18n.locale = $t('OTHER_LANGUAGE_CODE')">{{$t("OTHER_LANGUAGE")}}</a></li>
      </ul>
      <ul class="fNav-2">
        <li>
          <a @click="goto($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME)">{{$t("PAGE_PURCHASE_OF_CONSTRUCTION_EQUIPMENT")}}</a>
          <ul>
            <li>
              <a @click="goto($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME)">{{$t("PAGE_AUCTION")}}</a>
              <ul>
                <li><a @click="goto($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})">・ {{$t("PAGE_AUCTION_EXCAVATOR")}}</a></li>
                <li><a @click="goto($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})">・ {{$t("PAGE_AUCTION_CRANE")}}</a></li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <a @click="goto($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME)">{{$t("PAGE_TENDER")}}</a>
              <ul>
                <li><a @click="goto($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})">・ {{$t("PAGE_TENDER_EXCAVATOR")}}</a></li>
                <li><a @click="goto($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})">・ {{$t("PAGE_TENDER_CRANE")}}</a></li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <a @click="goto($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME)">{{$t("PAGE_INVENTORY_INFORMATION")}}</a>
              <ul>
                <li><a @click="goto($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})">・ {{$t("PAGE_INVENTORY_INFORMATION_EXCAVATOR")}}</a></li>
                <li><a @click="goto($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})">・ {{$t("PAGE_INVENTORY_INFORMATION_CRANE")}}</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <a @click="goto($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">{{$t("PAGE_MEMBER_REGISTRATION")}}</a>
          <ul>
            <li><a @click="goto($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t("PAGE_LOGIN")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">{{$t("PAGE_NEW_MEMBER_REGISTRATION")}}</a></li>
            <li v-if="logined"><a @click="goto($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)">{{$t("PAGE_MY_PAGE")}}</a></li>
          </ul>
        </li>
        <li>
          <a @click="goto($define.PAGE_NAMES.GUILD_PAGE_NAME)">{{$t("PAGE_BEGINNER_GUIDE")}}</a>
          <ul>
            <li><a @click="goto($define.PAGE_NAMES.GUILD_PAGE_NAME)">{{$t("PAGE_GUIDE_WEB_AUCTION_FLOW")}}</a></li>
          </ul>
        </li>
        <li>
          <a @click="goto($define.PAGE_NAMES.ACCESS_SHOP_LIST_PAGE_NAME)">{{$t("LINK_VENUE_ACCESS")}}</a>
          <ul>
            <li><a @click="goto($define.PAGE_NAMES.ACCESS_SHOP_LIST_PAGE_NAME)">{{$t("LINK_INTRODUCTION_SECONDHAND_CAR_CENTER")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.ACCESS_ICHIKAWA_PAGE_NAME)">{{$t("LINK_ICHIKAWA_SECONDHAND_CAR_CENTER")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.ACCESS_KOBE_PAGE_NAME)">{{$t("LINK_KOBE_SECONDHAND_CAR_CENTER")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.ACCESS_YOKOHAMA_PAGE_NAME)">{{$t("LINK_YOKOHAMA_SECONDHAND_CAR_CENTER")}}</a></li>
          </ul>
        </li>
        <li>
          {{$t("PAGE_GUIDANCE")}}
          <ul>
            <li><a @click="goto($define.PAGE_NAMES.FAQ_PAGE_NAME)">{{$t("LINK_GUIDANCE_FAQ")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.CONTACT_PAGE_NAME)">{{$t("PAGE_GUIDANCE_CONTACT")}}</a></li>
            <li><a href="https://www.kobelco-kenki.co.jp/products/index.html" target="_blank">{{$t("LINK_IN_HOUSE_CATALOG")}}</a></li>
            <li><a href="https://www.kobelco-kenki.co.jp/support/sales/index.html" target="_blank" v-html="$t('LINK_FOR_JAPANESE_PC')"></a></li>
          </ul>
        </li>
        <li>
          <a @click="goto($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_GUILD")}}</a>
          <ul>
            <li><a @click="goto($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_PROFILE")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.ORDER_RULE_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_ORDER_RULE")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.PRIVACY_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_PRIVACY")}}</a></li>
          </ul>
          <br /><br />
          <a @click="goto($define.PAGE_NAMES.ANNOUNCEMENT_PAGE_NAME)">{{$t("LINK_ANNOUNCEMENT")}}</a>
          <ul>
            <li><a @click="goto($define.PAGE_NAMES.ANNOUNCEMENT_PAGE_NAME)">{{$t("LINK_ANNOUNCEMENT_DENSI")}}</a></li>
            <!-- <li><a @click="goto($define.PAGE_NAMES.ANNOUNCEMENT_PAGE_NAME)">{{$t("LINK_ANNOUNCEMENT_KESSAI")}}</a></li> -->
          </ul>
        </li>
      </ul>
    </div>

    <!-- SP fNav -->
    <div class="fNav_sp only_sp">
      <ul>
        <li><a @click="goto($define.PAGE_NAMES.TOP_PAGE_NAME)">TOP</a></li>
        <li>
          <p>{{$t("PAGE_PURCHASE_OF_CONSTRUCTION_EQUIPMENT")}}</p>
          <dl>
            <dt><a @click="goto($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME)">{{$t("PAGE_AUCTION")}}</a></dt>
            <dd><a @click="goto($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})">・ {{$t("PAGE_AUCTION_EXCAVATOR")}}</a></dd>
            <dd><a @click="goto($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})">・ {{$t("PAGE_AUCTION_CRANE")}}</a></dd>
            <dt><a @click="goto($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME)">{{$t("PAGE_TENDER")}}</a></dt>
            <dd><a @click="goto($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})">・ {{$t("PAGE_TENDER_EXCAVATOR")}}</a></dd>
            <dd><a @click="goto($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})">・ {{$t("PAGE_TENDER_CRANE")}}</a></dd>
            <dt><a @click="goto($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME)">{{$t("PAGE_INVENTORY_INFORMATION")}}</a></dt>
            <dd><a @click="goto($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})">・ {{$t("PAGE_INVENTORY_INFORMATION_EXCAVATOR")}}</a></dd>
            <dd><a @click="goto($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})">・ {{$t("PAGE_INVENTORY_INFORMATION_CRANE")}}</a></dd>
          </dl>
        </li>
        <li>
          <p>{{$t("PAGE_MEMBER_REGISTRATION")}}</p>
          <ul>
            <li><a @click="goto($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t("PAGE_LOGIN")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">{{$t("PAGE_NEW_MEMBER_REGISTRATION")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)">{{$t("PAGE_MY_PAGE")}}</a></li>
          </ul>
        </li>
        <li>
          <p>{{$t("PAGE_BEGINNER_GUIDE")}}</p>
          <ul>
            <li><a @click="goto($define.PAGE_NAMES.GUILD_PAGE_NAME)">{{$t("PAGE_GUIDE_WEB_AUCTION_FLOW")}}</a></li>
          </ul>
        </li>
        <li>
          <p>{{$t("LINK_VENUE_ACCESS")}}</p>
          <ul>
            <li><a @click="goto($define.PAGE_NAMES.ACCESS_SHOP_LIST_PAGE_NAME)">{{$t("LINK_INTRODUCTION_SECONDHAND_CAR_CENTER")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.ACCESS_ICHIKAWA_PAGE_NAME)">{{$t("LINK_ICHIKAWA_SECONDHAND_CAR_CENTER")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.ACCESS_KOBE_PAGE_NAME)">{{$t("LINK_KOBE_SECONDHAND_CAR_CENTER")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.ACCESS_YOKOHAMA_PAGE_NAME)">{{$t("LINK_YOKOHAMA_SECONDHAND_CAR_CENTER")}}</a></li>
          </ul>
        </li>
        <li>
          <p>{{$t("PAGE_GUIDANCE")}}</p>
          <ul>
            <li><a @click="goto($define.PAGE_NAMES.FAQ_PAGE_NAME)">{{$t("LINK_GUIDANCE_FAQ")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.CONTACT_PAGE_NAME)">{{$t("PAGE_GUIDANCE_CONTACT")}}</a></li>
            <li><a href="https://www.kobelco-kenki.co.jp/products/index.html" target="_blank">{{$t("LINK_IN_HOUSE_CATALOG")}}</a></li>
            <li><a href="https://www.kobelco-kenki.co.jp/support/sales/index.html" target="_blank" v-html="$t('LINK_FOR_JAPANESE_SP')"></a></li>
          </ul>
        </li>
        <li>
          <p>{{$t("LINK_KOBELCO_KENKI_GUILD")}}</p>
          <ul>
            <li><a @click="goto($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_PROFILE")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.ORDER_RULE_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_ORDER_RULE")}}</a></li>
            <li><a @click="goto($define.PAGE_NAMES.PRIVACY_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_PRIVACY")}}</a></li>
          </ul>
        </li>
        <li>
          <p>{{$t("LINK_ANNOUNCEMENT")}}</p>
          <ul>
            <li><a @click="goto($define.PAGE_NAMES.ANNOUNCEMENT_PAGE_NAME)">{{$t("LINK_ANNOUNCEMENT_DENSI")}}</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
  <p class="copyright" v-html="$t('FOOTER_COPYRIGHT')"></p>
  <p class="kobutsu" v-html="$t('FOOTER_KOBUTSU')"></p>
</footer>
</template>
<script>
export default {
  props: {
    logined: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  methods: {
    goto (dest, id = null, query = {}, hash = null) {
      this.$routerGo(dest, id, query, hash)
      window.scrollTo({
        top      : 0,
        left     : 0,
        behavior : 'smooth'
      })
    }
  }
}
</script>
<style scoped lang="scss">
  p {
    margin-bottom: 0px !important;
  }
  ul {
    padding-left: 0px !important;
  }
  .unlink:hover {
    opacity: 1;
  }

  @media only screen and (max-width: 767px) {
    footer p.kobutsu {
      display: flex;
      flex-direction: column;
    }
  }
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
