/**
 * 定数定義
 */
const PAGE_NAMES = {
  LOGIN_PAGE_NAME                   : 'login',
  TOP_PAGE_NAME                     : 'index',
  DETAIL_PAGE_NAME                  : 'detail',
  CONTACT_PAGE_NAME                 : 'contact',
  ITEM_CONTACT_PAGE_NAME            : 'item_contact',
  STOCK_CONTACT_PAGE_NAME           : 'stock_contact',
  NEW_MEMBER_REGISTRATION_PAGE_NAME : 'entryinfo',
  MY_PAGE_PAGE_NAME                 : 'mypage',
  BID_HISTORY_PAGE_NAME             : 'history',
  CHANGE_INFO_PAGE_NAME             : 'change_info',
  REISSUE_PASSWORD_PAGE_NAME        : 'reissue_password',
  AUCTION_HISTORY_PAGE_NAME         : 'auction_history',
  AUCTION_LIST_PAGE_NAME            : 'auction',
  AUCTION_DETAIL_PAGE_NAME          : 'auction_detail',
  STOCK_LIST_PAGE_NAME              : 'stock',
  STOCK_DETAIL_PAGE_NAME            : 'stock_detail',
  TENDER_LIST_PAGE_NAME             : 'tender',
  TENDER_DETAIL_PAGE_NAME           : 'tender_detail',
  GUILD_PAGE_NAME                   : 'guild',
  OTHERS_PAGE_NAME                  : 'others',
  ORDER_RULE_PAGE_NAME              : 'order-rule',
  PRIVACY_PAGE_NAME                 : 'privacy',
  PROFILE_PAGE_NAME                 : 'profile',
  FAQ_PAGE_NAME                     : 'faq',
  ACCESS_PAGE_NAME                  : 'access',
  ACCESS_SHOP_LIST_PAGE_NAME        : 'shop_list',
  ACCESS_ICHIKAWA_PAGE_NAME         : 'ichikawa',
  ACCESS_KOBE_PAGE_NAME             : 'kobe',
  ACCESS_YOKOHAMA_PAGE_NAME         : 'yokohama',
  ANNOUNCEMENT_PAGE_NAME            : 'announcement',
}

const LOCAL_STORE_LABEL = {
  SESSION_TOKEN : 'ss_id'
}

module.exports = {
  PAGE_NAMES,
  LOCAL_STORE_LABEL
}
