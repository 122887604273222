<template>
  <v-app class="v-content-100vh">
    <Header :logined="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null"/>
    <v-main id="main">
      <div id="pNav" class="bgLGray" v-if="$route.meta.headerNameTag">
        <ul>
          <li><a @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME)">TOP</a></li>
          <li>{{$t($route.meta.headerNameTag)}}</li>
        </ul>
      </div>
      <router-view/>
    </v-main>
    <Footer :logined="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null"/>
    <v-dialog v-model="$store.state.loading" persistent fullscreen content-class="loading-dialog" transition="fade-transition">
      <v-container fill-height>
        <v-layout row justify-center align-center class="loading-icon">
          <v-progress-circular indeterminate :size="70" :width="7" color="green"></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
  </v-app>
</template>

<script>
import Footer from './components/Common/Footer'
import Header from './components/Common/Header'
import Methods from '@/api/methods'
export default {
  name: 'App',
  components: {
    Footer,
    Header
  },
  data() {
    return {
      scripts: [],
      focused: null
    }
  },
  created() {
    this.$moment.locale(this.$i18n.locale)
    window.document.title = this.$t('SITE_TITLE')
    document.addEventListener('focusin', this.focusIn)
    document.addEventListener('focusout', this.focusOut)
    document.addEventListener('keydown', event => {
      if (event.code === 'Space') {
        if (this.focused && this.focused.className.includes('space_click_event')) {
          this.focused.click()
          event.preventDefault()
        }
      }
    })
  },
  mounted () {
    Methods.addExternalScirpts(this.scripts, '')
    this.updateLoginStatus()
    document.body.classList.add('sub')
    document.body.id = 'top'
  },
  methods: {
    focusIn(event) {
      const el = event.target
      if (el) {
        el.className += ' focused'
        this.focused = el
      }
    },
    focusOut(event) {
      const el = event.target
      if (el.className) {
        el.className = el.className.replace('focused', '')
      }
    },
    updateLoginStatus() {
      if (this.$cookies.get(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)) {
        document.body.classList.remove('state-out')
        document.body.classList.add('state-in')
      } else {
        document.body.classList.remove('state-in')
        document.body.classList.add('state-out')
      }
    }
  },
  watch: {
    '$route.query.lang' (value) {
      this.$i18n.locale = this.$route.query.lang ? this.$route.query.lang : this.$i18n.locale
    },
    '$i18n.locale' (value) {
      this.$moment.locale(value)
      document.body.classList.remove('open')
      window.document.title = this.$t('SITE_TITLE')
    },
    $route () {
      this.updateLoginStatus()
      const classList = this.$router.options.routes.map(route => route.meta ? route.meta.bodyClass : null).filter(bodyClass => bodyClass)
      for (const row of classList) {
        document.body.classList.remove(row)
      }
      if (this.$route.meta.bodyClass) {
        document.body.classList.add(this.$route.meta.bodyClass)
      }
    }
  }
}
</script>
<style lang="scss">
  .loading-dialog {
    background-color: #303030;
    opacity: 0.3;
  }
  input.iptW-M, input.iptW-S, input.ime-dis, input.white-input {
    background-color: #fff;
  }
  .only_pc {
    font-weight: inherit;
  }
  @media only screen and (max-width: 767px) {
    .only_pc {
      display: none!important;
    }
  }
  .only_sp {
    font-weight: inherit;
  }
  @media only screen and (min-width: 768px) {
    .only_sp {
      display: none!important;
    }
  }
  span.checkbox-parts::before, span.checkbox-parts::after {
    -webkit-box-sizing: initial !important;
    box-sizing: initial !important;
  }
</style>

<style scoped lang="scss">
  .checkbox-parts.focused::before {
    border: 3px solid #000;
  }
</style>

<style lang="scss">
  #main #entry-form em.req {
    width: auto !important;
    max-width: 55px;
    padding: 0 5px;
  }
  #main #entry-form table.tbl-entry th {
    padding-right: 70px !important;
  }
</style>

<style lang="scss">
  .custom-tooltip {
    -moz-animation: tooltipAnimation 2s ease-out 1s forwards;
    /* Firefox */
    -webkit-animation: tooltipAnimation 2s ease-out 1s forwards;
    /* Safari and Chrome */
    -o-animation: tooltipAnimation 2s ease-out 1s forwards;
    /* Opera */
    animation: tooltipAnimation 2s ease-out 1s forwards;
  }
  @keyframes tooltipAnimation {
      0% {
        opacity: 1;
      }
      40% {
        opacity:  30%;
      }
      80% {
        opacity:  60%;
      }
      100% {
        opacity: 0;
        width: 0;
        height:0;
        overflow: hidden;
      }
  }
  @-webkit-keyframes tooltipAnimation {
      0% {
        opacity: 1;
      }
      40% {
        opacity:  30%;
      }
      80% {
        opacity:  60%;
      }
      100% {
        opacity: 0;
        width: 0;
        height:0;
        overflow: hidden;
      }
  }
</style>
<style lang="scss">
  .machine_list .stock-location,
  .mech_d-main-txt .stock-location,
  .machine_li-txt .stock-location,
  .target-item-txt .stock-location {
    font-size: 14px;
    color: #000;
    background-color: #EAEAEA;
    text-align: center;
    border-radius: 100vh;
    font-weight: 700;
    padding: 2px 15px;
    margin-right: 5px;
    letter-spacing: 0;
    height: 32px;
    line-height: 28px !important;
  }
  .mech_d-main-txt .stock-location {
    margin-bottom: 20px;
  }
</style>
<style lang="scss">
  @media only screen and (min-width: 641px) {
    .remodal {
      max-width: 700px !important;
    }
  }
  .remodal img {
    height: 100% !important;
    width: 100% !important;
  }
</style>
<style lang="scss">
  .show-password {
    margin-left: -25px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 0px !important;
    cursor: pointer !important;
  }
</style>
<style lang="scss">
  .error-msg {
    -webkit-box-align: center;
    align-items: center;
  }
  .error-msg .error-center {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
  }
  @media only screen and (min-width: 768px) {
    .error-msg {
      display: block;
    }
    .error-msg > p.err-txt{
      left: 370px !important;
      max-width: 350px !important;
    }
    .error-msg > p.file{
      left: 400px !important;
    }
    .error-msg .error-center > p.err-txt{
      position: initial;
      left: 0;
      margin-left: 10px;
      transform: none;
    }
  }
  @media only screen and (max-width: 767px) {
    .error-msg {
      display: flex;
    }
    .error-msg .error-center {
      display: flex;
      margin: 0;
      position: initial;
      top: 0;
      transform: none;
    }
    .error-msg > p.err-txt{
      width: 100%;
    }
  }

  #main #entry-info dl dd ol {
    justify-content: space-evenly !important;
  }
</style>
<style lang="scss">
  @media only screen and (max-width: 767px) {
    .error-dialog {
      max-width: 90%;
    }
    .bulk-bidding-dialog {
      margin: 0px !important;
      padding: 10px;
      .bidP-enter button {
        width: 90%;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .error-dialog {
      max-width: 767px;
    }
    .bulk-bidding-dialog {
      max-width: 1210px;
      padding: 20px;
      .bidP-enter button {
        width: 320px;
      }
      ul.machine_list > li {
        margin-bottom: 30px;
        margin-top: 0px;
      }
    }
  }

  .bulk-bidding-dialog .bidP-enter button {
    background-position: center right 15% !important;
  }
  .bulk-bidding-dialog .machine_list .machine_li-txt .stock-location {
    padding: 2px 11px !important;
  }
  .bulk-bidding-dialog .machine_list .machine_li-txt .price-system {
    padding: 2px 11px 0 !important;
  }
  .bulk-bidding-dialog .machine_list .machine_li-txt .recommending-status {
    padding: 5px 13px !important;
  }
  .bulk-bidding-dialog .machine_list .machine_li-txt .mach-no,
  .bulk-bidding-dialog .machine_list .machine_li-txt .stock-location,
  .bulk-bidding-dialog .machine_list .machine_li-txt .price-system,
  .bulk-bidding-dialog .machine_list .machine_li-txt .recommending-status,
  .bulk-bidding-dialog .machine_list .machine_li-txt .mach-data dl dt,
  .bulk-bidding-dialog .machine_list .machine_li-txt .mach-data dl dd,
  .bulk-bidding-dialog .machine_list .machine_li-txt .mach-price dl dt,
  .bulk-bidding-dialog .machine_list .machine_li-txt .mach-price .fin,
  .bulk-bidding-dialog .machine_list .machine_li-txt .mach-price .action-item .action-view span,
  .bulk-bidding-dialog .machine_list .machine_li-txt .mach-price .action-item .action-bid span {
    font-size:14px !important;
  }
  .bulk-bidding-dialog .machine_list .machine_li-txt .mach-price dl dd {
    font-size:20px !important;
  }
  .bulk-bidding-dialog .machine_list .machine_li-txt .mach-data dl dd.size-up {
    font-size:20px !important;
  }
  .bulk-bidding-dialog ul.machine_list > li {
    height: 85% !important;
  }
  .bulk-bidding-dialog .machine_list .machine_li-price .bidForm .bidPrice .bidP-tit {
    font-size:18px !important;
  }
  .bulk-bidding-dialog ul.machine_list > li .machine_li-txt .mach-price p.fin {
    left: 220px !important;
  }

  .bulk-bidding-card-text {
    overflow: auto;
    max-height: 70vh;
  }
  .bulk-bidding-dialog {
    -webkit-box-shadow: unset;
    box-shadow: unset;
    .theme--light.v-card {
      background-color: rgba(247,247,247,.95);
    }
  }
  .remodal-close {
    position: absolute;
    top: -18px;
    right: -18px;
    display: block;
    overflow: visible;
    width: 60px;
    height: 60px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
    text-decoration: none;

    color: #fff;
    border: 0;
    outline: 0;
    background-color: #01a7ac;
    border-radius: 50% !important;
  }

  .remodal-close:hover,
  .remodal-close:focus {
    color: #fff;
  }

  .remodal-close:before {
    font-size: 25px;
    line-height: 58px;

    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 60px;

    content: "×";
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    .remodal-close {
      width: 40px;
      height: 40px;
      top: -5px;
      right: -5px;
    }
    .remodal-close:before {
      font-size: 20px;
      width: 40px;
      line-height: 40px;
    }
  }
  .com-item-box {
    width: 100%
  }
  ul.machine_list > li div.btn-detail-disabled {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 60px;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: #01a7ac;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    letter-spacing: 10px;
    writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
}
.bid-confirm-message {
  width: 100%;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: 150;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.bid-confirm-success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #248f48;
    font-size: 40px !important;
    font-weight: 600 !important;
}
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
