import Vue from 'vue'
import Vuex from 'vuex'
import define from './api/define'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    define: define
  },
  mutations: {},
  actions: {}
})
