<template>
<header>
  <div class="wrap1160N">
    <div class="h-top">
      <h1 class="h-top-logo">
        <a class="logo" @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME)"><img src="@/assets/img/common/logo.png" alt="KOBELCO"></a>
        <p class="fontB">{{$t("SIDE_NAME")}}</p>
      </h1>
      <div class="h-top-menu">
        <ul v-if="logined && $route.name != $define.PAGE_NAMES.LOGIN_PAGE_NAME">
          <li v-if="$route.meta.headerBidding"><a class="btn-bid" @click="$routerGo($route.name, null, {filter : encodeURIComponent(JSON.stringify({bidding: true}))})">{{$t("HEADER_BIDDING_BUTTON_LABEL")}}</a></li>
          <li v-if="$route.meta.headerFavorite"><a class="btn-fav" @click="$routerGo($route.name, null, {filter : encodeURIComponent(JSON.stringify({favorite: true}))})">{{$t("HEADER_FAVORITE_LIST_BUTTON_LABEL")}}</a></li>
          <li><a class="btn-logout" @click="logout">{{$t("HEADER_LOGOUT_BUTTON_LABEL")}}</a></li>
          <li><a class="btn-page" @click="$routerGo($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)"><img src="@/assets/img/common/ic_page.svg" alt="マイページ"></a></li>
        </ul>
        <ul v-else>
          <li><a class="btn-page" @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t("PAGE_LOGIN")}}</a></li>
        </ul>
        <div class="lang">
          <a class="btn-lang" @click="$i18n.locale = $t('OTHER_LANGUAGE_CODE')">{{$t("OTHER_LANGUAGE_SHOT")}}</a>
        </div>
      </div>
      <p class="btnMenu only_sp"></p>
    </div>
  </div>
  <div class="gNav">
    <nav class="wrap1160N">
      <!-- PC gNav -->
      <ul class="only_pc">
        <li class="nav-top"><a @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME)">{{$t("PAGE_TOP")}}</a></li>
        <li class="nav-black">
          <a @click="$routerGo($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME)">{{$t("PAGE_AUCTION")}}</a>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})">・ {{$t("PAGE_AUCTION_EXCAVATOR")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})">・ {{$t("PAGE_AUCTION_CRANE")}}</a></li>
          </ul>
        </li>
        <li class="nav-black">
          <a @click="$routerGo($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME)">{{$t("PAGE_TENDER")}}</a>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})">・ {{$t("PAGE_TENDER_EXCAVATOR")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})">・ {{$t("PAGE_TENDER_CRANE")}}</a></li>
          </ul>
        </li>
        <li class="nav-black">
          <a @click="$routerGo($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME)">{{$t("PAGE_INVENTORY_INFORMATION")}}</a>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})">・ {{$t("PAGE_INVENTORY_INFORMATION_EXCAVATOR")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})">・ {{$t("PAGE_INVENTORY_INFORMATION_CRANE")}}</a></li>
          </ul>
        </li>
        <li>
          <a @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">{{$t("PAGE_MEMBER_REGISTRATION")}}</a>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t("PAGE_LOGIN")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">{{$t("PAGE_NEW_MEMBER_REGISTRATION")}}</a></li>
            <li v-if="logined"><a @click="$routerGo($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)">{{$t("PAGE_MY_PAGE")}}</a></li>
          </ul>
        </li>
        <li class="nav-first"><a @click="$routerGo($define.PAGE_NAMES.GUILD_PAGE_NAME)">{{$t("PAGE_BEGINNER_GUIDE")}}</a></li>
        <li class="nav-access">
          <a @click="$routerGo($define.PAGE_NAMES.ACCESS_SHOP_LIST_PAGE_NAME)">{{$t("LINK_VENUE_ACCESS")}}</a>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.ACCESS_SHOP_LIST_PAGE_NAME)">{{$t("LINK_INTRODUCTION_SECONDHAND_CAR_CENTER")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.ACCESS_ICHIKAWA_PAGE_NAME)">{{$t("LINK_ICHIKAWA_SECONDHAND_CAR_CENTER")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.ACCESS_KOBE_PAGE_NAME)">{{$t("LINK_KOBE_SECONDHAND_CAR_CENTER")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.ACCESS_YOKOHAMA_PAGE_NAME)">{{$t("LINK_YOKOHAMA_SECONDHAND_CAR_CENTER")}}</a></li>
          </ul>
        </li>
        <li><a @click="$routerGo($define.PAGE_NAMES.FAQ_PAGE_NAME)">{{$t("LINK_GUIDANCE_FAQ")}}</a></li>
        <li><a @click="$routerGo($define.PAGE_NAMES.CONTACT_PAGE_NAME)">{{$t("PAGE_GUIDANCE_CONTACT")}}</a></li>
        <li class="nav-overview">
          <a @click="$routerGo($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_GUILD")}}</a>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_PROFILE")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.ORDER_RULE_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_ORDER_RULE")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.PRIVACY_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_PRIVACY")}}</a></li>
          </ul>
        </li>
      </ul>

      <!-- SP gNav -->
      <ul class="only_sp">
        <li><a @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME)">{{$t("PAGE_TOP")}}</a></li>
        <li class="nav-black">
          <p>{{$t("PAGE_AUCTION")}}</p>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})">・ {{$t("PAGE_AUCTION_EXCAVATOR")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})">・ {{$t("PAGE_AUCTION_CRANE")}}</a></li>
          </ul>
        </li>
        <li class="nav-black">
          <p>{{$t("PAGE_TENDER")}}</p>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})">・ {{$t("PAGE_TENDER_EXCAVATOR")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.TENDER_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})">・ {{$t("PAGE_TENDER_CRANE")}}</a></li>
          </ul>
        </li>
        <li class="nav-black">
          <p>{{$t("PAGE_INVENTORY_INFORMATION")}}</p>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: false}))})">・ {{$t("PAGE_INVENTORY_INFORMATION_EXCAVATOR")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.STOCK_LIST_PAGE_NAME, null, {filter : encodeURIComponent(JSON.stringify({initCrane: true}))})">・ {{$t("PAGE_INVENTORY_INFORMATION_CRANE")}}</a></li>
          </ul>
        </li>
        <li>
          <p>{{$t("PAGE_MEMBER_REGISTRATION")}}</p>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME)">{{$t("PAGE_LOGIN")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)">{{$t("PAGE_NEW_MEMBER_REGISTRATION")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)">{{$t("PAGE_MY_PAGE")}}</a></li>
          </ul>
        </li>
        <li><a @click="$routerGo($define.PAGE_NAMES.GUILD_PAGE_NAME)">{{$t("PAGE_BEGINNER_GUIDE")}}</a></li>
        <li>
          <p>{{$t("LINK_VENUE_ACCESS")}}</p>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.ACCESS_SHOP_LIST_PAGE_NAME)">{{$t("LINK_INTRODUCTION_SECONDHAND_CAR_CENTER")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.ACCESS_ICHIKAWA_PAGE_NAME)">{{$t("LINK_ICHIKAWA_SECONDHAND_CAR_CENTER")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.ACCESS_KOBE_PAGE_NAME)">{{$t("LINK_KOBE_SECONDHAND_CAR_CENTER")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.ACCESS_YOKOHAMA_PAGE_NAME)">{{$t("LINK_YOKOHAMA_SECONDHAND_CAR_CENTER")}}</a></li>
          </ul>
        </li>
        <li><a @click="$routerGo($define.PAGE_NAMES.FAQ_PAGE_NAME)">{{$t("LINK_GUIDANCE_FAQ")}}</a></li>
        <li><a @click="$routerGo($define.PAGE_NAMES.CONTACT_PAGE_NAME)">{{$t("PAGE_GUIDANCE_CONTACT")}}</a></li>
        <li>
          <p>{{$t("LINK_KOBELCO_KENKI_GUILD")}}</p>
          <ul>
            <li><a @click="$routerGo($define.PAGE_NAMES.PROFILE_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_PROFILE")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.ORDER_RULE_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_ORDER_RULE")}}</a></li>
            <li><a @click="$routerGo($define.PAGE_NAMES.PRIVACY_PAGE_NAME)">{{$t("LINK_KOBELCO_KENKI_PRIVACY")}}</a></li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</header>
</template>
<script>
export default {
  props: {
    logined: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  methods: {
    logout() {
      this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)
      this.$routerGo(this.$define.PAGE_NAMES.LOGIN_PAGE_NAME)
    }
  }
}
</script>
<style scoped lang="scss">
  a.focused {
    border-bottom: 2px solid #01a7ac !important;
  }
  #header-index {
    #pNav > ul {
      width: 100%;
      max-width: 1600px;
      display: flex;
      flex-wrap: wrap;
      padding: 10px 20px;
      margin: 0 auto;
    }
    #pNav > ul > li {
      font-size: 14px;
    }
    #pNav > ul > li:not(:last-of-type)::after {
      content: "＞";
      display: inline-block;
      margin: 0 5px;
    }
    #pNav > ul > li a {
      color: #01a7ac;
    }
    @media only screen and (max-width: 767px) {
      #pNav > ul {
        flex-wrap: nowrap;
        overflow-x: auto;
        word-break: keep-all;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
</style>
<style scoped lang="scss">
  p {
    margin-bottom: 0px !important;
  }
  ul {
    padding-left: 0px !important;
  }
  a {
    color: #333 !important;
  }
  header .h-top .h-top-menu a {
    color: #fff !important;
  }
  header .gNav nav > ul > li.nav-black > a {
    background-color: #000;
    color: #fff !important;
  }
  @media only screen and (min-width: 768px) {
    header .gNav nav > ul > li > a {
      text-align: center;
    }
  }
  @media only screen and (max-width: 767px) {
    header .h-top .h-top-logo p {
      max-width: calc(100vw - 52px - 50px - 73px - 50px);
    }
  }
</style>
<style scoped src="@/assets/css/set.css">
</style>
<style scoped src="@/assets/css/margin.css">
</style>
<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/parts.css">
</style>
